import { logo } from "./logo";

import {
  cilUser,
  cilLockLocked,
  cilSpeedometer,
  cilApplications,
  cilPencil,
  cilNotes,
  cilHeart,
  cilAccountLogout,
  cilPenAlt,
  cilTrash,
  cilPlus,
  cilSave,
  cilBan,
  cilFile,
  cilLibrary,
  cilPuzzle,
  cilText,
  cilCalendar,
  cilImage1,
  cilShortText,
  cilLink,
  cilFolder,
  cilChevronLeft,
  cilReload,
  cibGoogle,
  cilBadge,
  cilMagnifyingGlass,
  cilBriefcase,
  cilCheckCircle,
  cilClipboard,
} from "@coreui/icons";

export const icons = {
  logo,
  cilUser,
  cilLockLocked,
  cilSpeedometer,
  cilApplications,
  cilPencil,
  cilNotes,
  cilHeart,
  cilAccountLogout,
  cilPenAlt,
  cilTrash,
  cilPlus,
  cilSave,
  cilBan,
  cilFile,
  cilLibrary,
  cilPuzzle,
  cilText,
  cilCalendar,
  cilImage1,
  cilShortText,
  cilLink,
  cilFolder,
  cilChevronLeft,
  cilReload,
  cibGoogle,
  cilBadge,
  cilMagnifyingGlass,
  cilBriefcase,
  cilCheckCircle,
  cilClipboard,
};
