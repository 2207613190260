export const USER_KEY = "PRIME_USER";
export const MODEL_KEY = "PRIME_MODELS";

export const DICEBARE_AVATAR = "https://avatars.dicebear.com/api/bottts/";

export const DEBOUNCE_DELAY = 400; // ms

export const GROUP = {
  TEER: "digiteer",
  ADMIN: "admin",
  EDITOR: "editor",
  NONE: "",
};

export const GROUP_ALL = [GROUP.TEER, GROUP.ADMIN, GROUP.EDITOR];
